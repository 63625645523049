<template>
    <div class="h-full overflow-auto">
        <div 
            v-if="wordpress"
            class="container mx-auto flex my-12 py-12"> 
            
            <div class="mx-auto w-2/4">
                <h2 
                    v-html="wordpress.title.rendered" 
                    class="font-bold text-lo-pink mb-6"
                />
                
                <img 
                    v-if="featuredmedia"
                    :src="featuredmedia" 
                    alt="Illustrasjonsbilde"
                    class="w-full mb-6"
                />
                <div 
                    v-if="id == 518"
                    class="flex mb-6 -mx-2">
                    
                    <a 
                        class="w-1/3"
                        href="https://digitalnorway.com/korte-introkurs"
                        target="_blank">
                        
                        <img 
                            src="/digitalnorway/introkurs.png" 
                            alt="korte-introkurs" 
                        />
                    </a>
                    <a 
                        class="w-1/3"
                        href="https://digitalnorway.com/digitale-teknologier"
                        target="_blank">
                        
                        <img 
                            src="/digitalnorway/dybdekurs.png" 
                            alt="dybdekurs" 
                        />
                    </a>
                    <a 
                        class="w-1/3"
                        href="https://digitalnorway.com/kurs-og-kompetanse/veivisere"
                        target="_blank">
                        
                        <img 
                            src="/digitalnorway/veivisere.png" 
                            alt="veivisere" 
                        />
                    </a>
                    
                </div>
                
                <div v-html="wordpress.content.rendered" />
            </div>
        </div>
        
        <Footer />
    </div>
</template>

<script>
    export default {
        components: {
            'Footer': () => import('@/components/Footer.vue'),
        },
        
        computed: {
            featuredmedia(){
                try {
                    return this.wordpress['_embedded']['wp:featuredmedia'][0]['media_details']['sizes']['full']['source_url'];
                } 
                catch (e) {
                    return null;
                }
            }
        },
        
        data(){
            return {
                wordpress: null,
                id: null,
            }
        },
        
        methods: {
            async fetchStart(){
                try {
                    const response = await this.$axios.get( this.wordpressApiEndpoint + '/pages/'+this.id+'?_embed');
                    this.wordpress = response.data;
                }
                catch (error) {
                    console.error(error);
                }
            },
        },
        
        async mounted(){
            this.id = this.$route.params.id;
            await this.fetchStart();
            
			this.$events.on('logoClicked',  () => {
                // this.showPromo = true; 
                // this.showIntro = false;
                // this.showRegister = false;
                this.$router.push({ name: 'hjem'})

                // :to="{ path: 'artikkel/383'}"
                // alert('artikkel click!');
			});
        }
        
    }
</script>

<style lang="scss">

</style>
